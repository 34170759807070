<template>
   <div v-if="!adAlreadyLoaded" :class="{'hidden' : !visible }" class="fixed inset-0 flex items-center justify-center z-50">
      <!-- Backdrop -->
      <div class="absolute inset-0 bg-black opacity-60" @click="onCloseClicked"/>
      <div class="relative bg-transparent w-auto">
         <div class="flex justify-end">
            <button
               class="bg-surface-300 text-black font-bold px-4 py-2 rounded-t-lg"
               @click="onCloseClicked"
            >
               Close
            </button>
         </div>
         <AdContainer
            v-if="breakpoints.isGreaterOrEqual('sm')"
            class="hidden lg:flex w-full justify-center items-center"
            :ad-unit-id="inventory.takeover_desktop.id"
            :google-ad="inventory.takeover_desktop"
         />
         <AdContainer
            v-if="breakpoints.isSmaller('sm')"
            class="flex lg:hidden w-full justify-center items-center"
            :ad-unit-id="inventory.takeover_mobiles.id"
            :google-ad="inventory.takeover_mobiles"
         />
      </div>
   </div>

</template>

<script setup lang="ts">

   import AdContainer from '~/components/Ads/AdContainer.vue';
   import {breakpointsTailwind, useBreakpoints} from '@vueuse/core';

   const breakpoints = useBreakpoints(breakpointsTailwind);
   const {inventory} = useGoogleAds();
   const visible = ref(false);
   const adAlreadyLoaded = ref(false);

   const onCloseClicked = () => {
      visible.value = false;
      adAlreadyLoaded.value = true;
   };

   onMounted(() => {
      if (typeof googletag !== 'undefined' && typeof googletag.pubads === 'function') {
         googletag.pubads().addEventListener('slotRenderEnded', (event) => {
            const id = event.slot.getSlotElementId();

            if (id === inventory.takeover_desktop.id || id === inventory.takeover_mobiles.id) {
               if (event.isEmpty) {
                  return;
               }


               if (adAlreadyLoaded.value && event.isEmpty) {
                  return;
               }
               visible.value = true;
            }

         });
      }


   });

</script>


<style scoped>

</style>