<template>
   <div
      v-if="!adAlreadyLoaded"
      class="fixed bottom-0 right-0 m-4 p-4 transition-transform duration-500 z-50"
      :class="{ 'translate-y-full': !visible, 'translate-y-0': visible }"
      @transitionend="onTransitionEnd">
      <div class="flex flex-col items-center lg:items-end justify-center sm:justify-end">
         <button class="w-20 p-2 bg-surface-300 rounded-t-lg font-bold" @click="onCloseClicked">Close</button>
         <AdContainer
            class="flex w-full justify-center items-center"
            :ad-unit-id="inventory.catfish.id"
            :google-ad="inventory.catfish"
         />
      </div>
   </div>
</template>

<script setup lang="ts">

   import AdContainer from '~/components/Ads/AdContainer.vue';

   const {inventory} = useGoogleAds();
   const visible = ref(false);
   const adAlreadyLoaded = ref(false);

   const onCloseClicked = () => {
      visible.value = false;
      adAlreadyLoaded.value = true;
   };

   onMounted(() => {

      googletag.pubads().addEventListener('slotRenderEnded', (event) => {
         const id = event.slot.getSlotElementId();

         if (id === inventory.catfish.id) {
            if (event.isEmpty) {
               return;
            }

            if (adAlreadyLoaded.value) {
               return;
            }
            visible.value = true;
         }

      });
   });
   function onTransitionEnd() {
      // Handle any cleanup or additional logic after the transition ends
   }

</script>

<style scoped>

</style>
