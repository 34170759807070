<template>
   <svg
      id="Layer_1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
      y="0px" viewBox="0 0 584.94 132.3" style="enable-background:new 0 0 584.94 132.3;" xml:space="preserve">
      <g>
         <rect x="413.66" y="0.23" class="st0" width="164.44" height="131.84"/>
         <g>
            <path
               d="M50.82,69.15c0-5.06-1.51-8.9-4.57-11.51c-3.06-2.65-7.06-3.96-12.08-3.96c-5.06,0-9.1,1.31-12.12,3.92
			c-3.02,2.57-4.53,6.49-4.53,11.63l0,5.22l33.34,0v-5.3H50.82z M61.15,69.15l0,12.16c0,0.98-0.49,1.51-1.43,1.51l-42.23,0l0,5.22
			c0,4.53,1.55,8.08,4.65,10.53c3.1,2.49,7.18,3.71,12.12,3.71c7.06,0,13.63-2.33,19.75-6.94c0.86-0.53,1.55-0.41,2.08,0.41
			l3.79,4.69c0.61,0.65,0.53,1.31-0.24,2c-7.43,5.96-15.91,8.94-25.54,8.94c-7.51,0-13.91-2.04-19.26-6.12
			c-5.35-4.12-8-9.87-8-17.22l0-18.89c0-8.04,2.65-14.16,7.96-18.4c5.3-4.24,11.75-6.32,19.42-6.32c7.55,0,14,2.12,19.18,6.32 C58.53,54.99,61.15,61.11,61.15,69.15"/>
            <path
               d="M123.82,104.16c-6.81,4.77-14.4,7.22-22.85,7.22s-15.14-2.2-20.16-6.65s-7.47-10.24-7.47-17.34l0-18.85
			c0-7.14,2.49-12.89,7.47-17.38c5.02-4.45,11.67-6.69,20.04-6.69c7.51,0,14.49,2.08,21.01,6.2c0.86,0.61,0.98,1.31,0.41,2.12
			l-3.39,5.1c-0.61,0.73-1.31,0.86-2.08,0.33c-4.9-3.06-10.16-4.57-15.75-4.57c-5.39,0-9.63,1.39-12.65,4.08
			c-3.02,2.73-4.53,6.32-4.53,10.77l0,18.85c0,4.57,1.51,8.2,4.53,10.89c3.02,2.73,7.18,4,12.53,4c5.92,0,11.71-1.8,17.3-5.43
			c0.78-0.65,1.47-0.57,2.08,0.24l3.75,5.1C124.68,103.02,124.6,103.67,123.82,104.16"/>
            <path
               d="M143.82,87.02c0,4.69,1.51,8.45,4.57,11.18c3.06,2.73,7.43,4.08,13.14,4.08c5.67,0,10.04-1.39,13.18-4.08
			c3.1-2.73,4.65-6.49,4.65-11.18V69.15c0-4.77-1.55-8.61-4.65-11.34c-3.1-2.77-7.47-4.16-13.18-4.16c-5.71,0-10.04,1.39-13.14,4.16
			c-3.06,2.77-4.57,6.57-4.57,11.34V87.02z M189.89,87.02c0,7.39-2.53,13.26-7.67,17.71s-12.04,6.65-20.73,6.65
			c-8.73,0-15.63-2.24-20.73-6.69c-5.14-4.49-7.67-10.36-7.67-17.67l0-17.87c0-7.39,2.57-13.34,7.71-17.87
			c5.18-4.53,12.04-6.81,20.69-6.81c8.69,0,15.59,2.29,20.73,6.81c5.14,4.53,7.67,10.49,7.67,17.87
			C189.89,69.15,189.89,87.02,189.89,87.02z"/>
            <path
               d="M245.34,50.5c6.41-4.04,12.98-6.08,19.87-6.08c8.12,0,14.53,2.2,19.42,6.65c4.9,4.45,7.3,10.65,7.3,18.69l0,38.89
			c0,0.9-0.49,1.39-1.43,1.39h-7.59c-1.02,0-1.55-0.49-1.55-1.39l0-39.09c0-5.06-1.39-8.98-4.16-11.75
			c-2.77-2.77-6.81-4.16-12.24-4.16c-5.26,0-9.96,1.43-14.2,4.24c1.63,3.43,2.45,7.34,2.45,11.79l0,39.01c0,0.9-0.49,1.39-1.43,1.39
			h-7.71c-0.94,0-1.43-0.49-1.43-1.39l0-39.09c0-10.61-5.14-15.91-15.51-15.91c-4.41,0-8.73,0.78-13.02,2.24l0,52.8
			c0,0.9-0.53,1.39-1.51,1.39h-7.63c-0.94,0-1.43-0.49-1.43-1.39l0-57.7c0-0.82,0.41-1.43,1.18-1.71
			c7.39-3.22,14.81-4.82,22.24-4.82C234.49,44.46,240.61,46.5,245.34,50.5"/>
            <path
               d="M348.58,50.5c6.41-4.04,12.98-6.08,19.91-6.08c8.08,0,14.53,2.2,19.42,6.65c4.9,4.45,7.3,10.65,7.3,18.69l0,38.89
			c0,0.9-0.49,1.39-1.43,1.39h-7.63c-1.02,0-1.55-0.49-1.55-1.39V69.56c0-5.06-1.39-8.98-4.16-11.75s-6.86-4.16-12.24-4.16
			c-5.26,0-9.96,1.43-14.2,4.24c1.63,3.43,2.45,7.34,2.45,11.79v39.01c0,0.9-0.49,1.39-1.43,1.39h-7.71c-0.94,0-1.43-0.49-1.43-1.39
			l0-39.09c0-10.61-5.18-15.91-15.51-15.91c-4.41,0-8.73,0.78-13.02,2.24l0,52.8c0,0.9-0.53,1.39-1.55,1.39h-7.63
			c-0.94,0-1.43-0.49-1.43-1.39l0-57.7c0-0.82,0.41-1.43,1.18-1.71c7.39-3.22,14.81-4.82,22.2-4.82
			C337.64,44.46,343.8,46.5,348.58,50.5"/>
            <path
               class="st1" d="M460.3,102.28c12.2,0,18.36-5.92,18.36-17.75V70.78c0-11.51-6.08-17.26-18.2-17.26
			c-5.79,0-10.89,1.47-15.38,4.45l0,41.95C449.33,101.51,454.34,102.28,460.3,102.28 M445.08,48.3c5.18-2.53,10.61-3.84,16.28-3.84
			c8.32,0,15.1,2.37,20.24,7.02c5.18,4.65,7.75,11.1,7.75,19.3l0,13.75c0,9.02-2.65,15.75-7.79,20.16
			c-5.22,4.45-12.16,6.65-20.97,6.65c-8.77,0-17.1-1.43-24.89-4.33c-0.78-0.33-1.18-0.86-1.18-1.59l0-82.83
			c0-0.9,0.49-1.35,1.43-1.35h7.59c1.02,0,1.55,0.45,1.55,1.35C445.08,22.59,445.08,48.3,445.08,48.3z"/>
            <path
               class="st1" d="M534.73,77.59l22.44,30.64c0.78,1.18,0.49,1.71-0.9,1.71h-9.55c-0.78,0-1.35-0.24-1.67-0.86l-16.77-24.48
			l-16.77,24.48c-0.33,0.61-0.94,0.86-1.8,0.86h-9.59c-1.39,0-1.67-0.57-0.9-1.71l22.44-30.64l-21.1-30.03l-0.24-1.18
			c0.16-0.41,0.57-0.57,1.18-0.57l9.02,0c0.69,0,1.31,0.33,1.8,0.98l15.87,23.59l15.87-23.59c0.53-0.65,1.06-0.98,1.67-0.98h9.02
			l1.26,0.57l-0.2,1.18L534.73,77.59z"/>
         </g>
      </g>
   </svg>

</template>

<script setup lang="ts">

</script>


<style scoped>
.st0 {
  fill: #E1251B;
}

.st1 {
  fill: #FFFFFF;
}
</style>